import React from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Select, Textarea, Button, Message, Spinner } from 'theme-ui'

const ContactForm = ({ handleSubmit, submitting, success }) => (
  <form
    onSubmit={handleSubmit}
    method='POST'
    action='https://aircodr-com-2372.twil.io/aircodr-com_contact_form'
  >
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-name'>Nom</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input
            type='email'
            placeholder='email@example.com'
            id='contact-form-email'
            name='email'
            required
        />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-serviceDelivery'>Comment je peux vous aider ?</Label>
        <Select id="contact-form-serviceDelivery" name="serviceDelivery">
          <option>Projet</option>
          <option>Coaching</option>
          <option>Audit</option>
          <option>Question</option>
        </Select>
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-budget'>Budget</Label>
        <Select id="contact-form-budget" name="budget">
          <option>-</option>
          <option>inférieur à 5 000€</option>
          <option>de 5 000€ à 10 000€</option>
          <option>de 10 000€ à 25 000€</option>
          <option>supérieur à 25 000€</option>
        </Select>
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-subject'>Sujet</Label>
      <Input type='text' id='contact-form-subject' name='subject' required />
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-message'>Votre message</Label>
      <Textarea name='message' id='contact-form-message' />
    </Box>
    {success === true && (
        <Message variant='success'>
          Merci de m'avoir contacté. Je reviens vers vous rapidement !
        </Message>
    )}
    {success === false && (
        <Message variant='error'>
          Quelque chose s'est mal passé. Veuillez réessayer plus tard !
        </Message>
    )}
    <Button
      variant={submitting ? 'disabled' : 'primary'}
      disabled={submitting}
      type='submit'
      required
    >
      Envoyer {submitting && <Spinner size='20' />}
    </Button>
  </form>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
