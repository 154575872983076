import React from 'react'
import {Layout, Main, Stack} from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

const PageContact = props => (
    <Layout {...props}>
        <Seo title='Contact'/>
        <Divider/>
        <Stack>
            <Main>
                <PageTitle
                    header="Rencontrons-nous"
                    subheader='Parlez-moi de votre projet ou de vos problèmes. Je vous répondrai dans les plus bref délais.'
                />
                <Divider/>
                <ContactForm/>
            </Main>
        </Stack>
    </Layout>
)

export default PageContact
